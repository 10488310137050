import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

class Gallery {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.slider = this.root.querySelector('[data-gallery-slider]');
        this.pagination = this.root.querySelector(
            '[data-gallery-slider-pagination]'
        );

        this.prevEl = this.root.querySelector('[data-gallery-prev]');
        this.nextEl = this.root.querySelector('[data-gallery-next]');

        return true;
    }

    init() {
        this.initSlider();
    }

    initSlider() {
        if (!this.slider) return;

        const initOptions = {
            modules: [Pagination, Navigation],
            slidesPerView: 'auto',
            spaceBetween: 16,
            pagination: {
                el: this.pagination,
                clickable: true,
                type: 'bullets',
                bulletClass: 'slider-pagination__bullet',
                bulletActiveClass: 'active',
            },
        };

        if (this.nextEl && this.prevEl) {
            initOptions.navigation = {
                prevEl: this.prevEl,
                nextEl: this.nextEl,
            };
        }

        this.swiper = new Swiper(this.slider, initOptions);
    }
}

export default function initGalleries() {
    const sections = document.querySelectorAll('[data-gallery]');
    if (sections) sections.forEach(item => new Gallery(item));
}
