import initArticleCards from './modules/card-article';
import initGalleries from './modules/gallery';
import { hamburger } from './modules/header.js';
import setMarquees from './modules/marquee';
import initSearchResults from './modules/search.js';
import initSplitText from './modules/text-split';
import initVideos from './modules/video';

var documentReady = function () {
    // Zmienna globalna przenosząca tłumaczenia. Konfigurowalna w config.php.

    // RxJS working
    //fromEvent(document, "click").subscribe(() => {
    //    test();
    //});
    hamburger();
    setMarquees();
    initGalleries();
    initVideos();
    initSplitText();
    initSearchResults();
    initArticleCards();
};

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}
