import debounce from 'lodash.debounce';
$ = jQuery;

export function hamburger() {
    let lastScrollTop = 0;
    const hamburger = document.querySelector('#js-burger');
    const menuMobileClose = document.querySelector('#js-menu-mobile-close');
    const categories = document.querySelector('#js-categories');
    const logo = document.querySelector('.header__logo');
    const header = document.querySelector('.header');
    const box = document.querySelector('.header__box');
    const lines = document.querySelectorAll('.header__line');
    const searchButton = document.querySelector('#headerSearchToggle');
    const menuSearch = document.querySelector('#headerMenuSearch');
    const menuSearchInput = document.querySelector('#headerMenuSearchInput');
    const menuSearchClose = document.querySelector('#headerMenuSearchClose');
    const menuSearchResults = document.querySelector(
        '#headerMenuSearchResults'
    );
    const headerResultsMobile = document.querySelector('#headerResultsMobile');
    const headerSearchMobile = document.querySelector('#headerSearchMobile');

    const menuContainer = document.querySelector('.header__menu-container');
    const iconCategories = document.querySelector('.header__categories');

    const mobileFixedMenu = document.querySelector('[data-mobile-fixed-menu]');

    if (mobileFixedMenu) {
        window.addEventListener('scroll', event => {
            const { scrollY } = window;
            if (scrollY > 100) {
                mobileFixedMenu.classList.add('scrolled');
            } else {
                mobileFixedMenu.classList.remove('scrolled');
            }
        });
    }

    hamburger.addEventListener('click', function () {
        if (hamburger.classList.contains('is-active')) {
            document.body.style.overflow = '';
            hamburger.classList.remove('is-active');
            logo.classList.remove('header__logo--active');
            header.classList.remove('header--active');
            box.classList.remove('header__box--active');
            lines.forEach(line => {
                line.classList.remove('header__line--active');
            });
        } else {
            document.body.style.overflow = 'hidden';
            hamburger.classList.add('is-active');
            logo.classList.add('header__logo--active');
            header.classList.add('header--active');
            box.classList.add('header__box--active');
            lines.forEach(line => {
                line.classList.add('header__line--active');
            });
        }
    });

    menuMobileClose.addEventListener('click', function () {
        document.body.style.overflow = '';
        hamburger.classList.remove('is-active');
        logo.classList.remove('header__logo--active');
        header.classList.remove('header--active');
        box.classList.remove('header__box--active');
        lines.forEach(line => {
            line.classList.remove('header__line--active');
        });
    });

    categories.addEventListener('click', function () {
        if (categories.classList.contains('is-active')) {
            document.body.style.overflow = '';
            categories.classList.remove('is-active');
            box.classList.remove('header__box--active');
            header.classList.remove('header--active');
            menuContainer.classList.remove('header__menu-container--active');
            iconCategories.classList.remove('header__categories--active');
        } else {
            document.body.style.overflow = 'hidden';
            categories.classList.add('is-active');
            box.classList.add('header__box--active');
            header.classList.add('header--active');
            menuContainer.classList.add('header__menu-container--active');
            iconCategories.classList.add('header__categories--active');
        }
    });

    window.addEventListener('scroll', event => onWindowScroll(event));
    window.addEventListener('click', event => onClickOutside(event));

    setWindowResizeAssets();

    if (searchButton)
        searchButton.addEventListener('click', event => onSearchToggle(event));
    if (menuSearchInput)
        menuSearchInput.addEventListener('keyup', event =>
            onMenuSearchInput(event)
        );
    if (menuSearchClose)
        menuSearchClose.addEventListener('click', event =>
            onMenuSearchClose(event)
        );

    if (headerSearchMobile)
        headerSearchMobile.addEventListener('keyup', event =>
            onMenuSearchInputMobile(event)
        );

    function onClickOutside(event) {
        const { target } = event;
        if (!header.contains(target)) {
            document.body.style.overflow = '';
            categories.classList.remove('is-active');
            box.classList.remove('header__box--active');
            header.classList.remove('header--active');
            menuContainer.classList.remove('header__menu-container--active');
            iconCategories.classList.remove('header__categories--active');
        }
    }

    function onWindowScroll(event) {
        const { scrollY } = window;
        if (scrollY > lastScrollTop) {
            header.classList.add('header--scrolled');
        } else {
            header.classList.remove('header--scrolled');
        }

        if (scrollY <= 0) header.classList.remove('header--scrolled');
        lastScrollTop = scrollY;
    }

    function setWindowResizeAssets() {
        onWindowResize();
        window.addEventListener('resize', event => onWindowResize(event));
    }

    function onWindowResize(event) {
        const { innerHeight } = window;
        const { clientHeight } = header;
        const root = document.querySelector(':root');
        root.style.setProperty('--window-height', `${innerHeight}px`);
        root.style.setProperty('--header-height', `${clientHeight}px`);
    }

    function onSearchToggle(event) {
        if (!menuSearch) return;
        menuSearch.classList.toggle('active');
    }

    function onMenuSearchClose(event) {
        if (!menuSearch) return;
        menuSearch.classList.remove('active');
    }

    function onMenuSearchInput(event) {
        const { currentTarget } = event;
        const { value } = currentTarget;

        if (value.length > 0) {
            currentTarget.classList.add('filled');
        } else {
            currentTarget.classList.remove('filled');
        }

        if (value.length >= 3) {
            menuSearch.classList.add('filled');
            getSearchResults(value);
        } else {
            menuSearch.classList.remove('filled');
        }
    }

    function onMenuSearchInputMobile(event) {
        const { currentTarget } = event;
        const { value } = currentTarget;

        if (value.length >= 3) {
            headerResultsMobile.classList.add('active');
            getSearchResults(value);
        } else {
            headerResultsMobile.classList.remove('active');
        }
    }

    const getSearchResults = debounce(searchResults, 500);

    function searchResults(phrase) {
        const formData = new FormData();
        formData.append('phrase', phrase);

        $.ajax({
            type: 'GET',
            url: `${home_url}/wp-json/articles/v1/search`,
            data: {
                phrase,
            },
            success: function (response) {
                const data = response;
                const { articles_html } = data;
                menuSearchResults.innerHTML = articles_html;
                headerResultsMobile.innerHTML = articles_html;
            }.bind(this),
            error: function () {},
            complete: function () {},
        });
    }
}
