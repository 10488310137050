import videojs from 'video.js';

class VideoSection {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;
        this.root = el;
        this.video = this.root.querySelector('[data-video-js]');
        this.toggle = this.root.querySelector('[data-video-toggle]');

        this.play = false;
        this.instance = null;

        return true;
    }

    init() {
        this.setVideo();
        this.setToggle();
    }

    setVideo() {
        if (!this.video) return;
        this.instance = videojs(this.video);
    }

    setToggle() {
        if (!this.toggle) return;
        this.toggle.addEventListener('click', this.onToggle.bind(this));
    }

    onToggle() {
        if (!this.toggle) return;
        this.play = !this.play;

        const span = this.toggle.querySelector('span');
        const toggleClass = this.play ? 'pause' : 'play';
        const rootClass = this.play ? 'play' : 'pause';
        const spanClass = this.play ? 'icon-pause' : 'icon-play';

        this.root.classList.remove(...['pause', 'play']);
        this.root.classList.add(rootClass);

        this.toggle.classList.remove(...['pause', 'play']);
        this.toggle.classList.add(toggleClass);

        if (span) {
            span.classList.remove(...['icon-play', 'icon-pause']);
            span.classList.add(spanClass);
        }

        this.play ? this.instance.play() : this.instance.pause();
    }
}

const initVideos = () => {
    const sections = document.querySelectorAll('[data-video]');
    if (!sections) return;
    sections.forEach(item => new VideoSection(item));
};

export default initVideos;
