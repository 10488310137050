class TextSplit {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.spans = this.root.querySelectorAll('span');
        this.isHero = this.root.getAttribute('data-text-split-hero');

        return true;
    }

    init() {
        setTimeout(() => {
            this.initWindow();
            this.setSpans();
        }, 1500);
    }

    initWindow() {
        window.addEventListener('resize', this.onResize.bind(this));
    }

    setSpans() {
        const textContainer = this.root;
        const spans = this.spans;
        const rootStyles = window.getComputedStyle(this.root);
        let rootLineHeight = rootStyles.getPropertyValue('line-height');
        rootLineHeight = rootLineHeight.replace('px', '');

        let linesInfo = [];
        let currentLine = { text: '', width: 0, wordCount: 0 };

        for (let i = 0; i < spans.length; i++) {
            const span = spans[i];
            const text = span.textContent.trim();
            const spanWidth = span.offsetWidth;

            if (text === '') continue;

            let spaceWidth = 8;

            if (
                spanWidth +
                    currentLine.width +
                    (currentLine.wordCount > 0 ? spaceWidth : 0) >
                textContainer.offsetWidth
            ) {
                linesInfo.push(currentLine);
                currentLine = { text: text, width: spanWidth, wordCount: 1 };
            } else {
                if (currentLine.text !== '') {
                    currentLine.text +=
                        (currentLine.wordCount > 0 ? ' ' : '') + text;
                } else {
                    currentLine.text = text;
                }
                currentLine.width +=
                    spanWidth + (currentLine.wordCount > 0 ? spaceWidth : 0);
                currentLine.wordCount++;
            }
        }

        if (currentLine.text !== '') linesInfo.push(currentLine);
        this.checkLines();

        linesInfo.forEach((item, index) => {
            const { width } = item;

            const lineEl = document.createElement('div');
            lineEl.classList.add('split-title__line');
            lineEl.style.top = `${index * rootLineHeight}px`;
            lineEl.style.width = `${width + 24}px`;

            this.root.appendChild(lineEl);
        });
    }

    // check and remove lines
    checkLines() {
        const prevLines = this.root.querySelectorAll('.split-title__line');
        if (!prevLines) return;

        prevLines.forEach(item => item.remove());
    }

    onResize() {
        this.setSpans();
    }
}

const initSplitText = () => {
    const sections = document.querySelectorAll('[data-text-split]');
    if (sections) sections.forEach(item => new TextSplit(item));
};

export default initSplitText;