class CardArticle {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.topEl = this.root.querySelector('.card-article__top');
        this.tagsEl = this.root.querySelectorAll('.tag');
        this.dateEl = this.root.querySelector('.card-article__date');
        this.contentEl = this.root.querySelector('.card-article__content');

        this.isFeatured = this.root.classList.contains(
            'card-article--featured'
        );

        return true;
    }

    init() {
        if (this.isFeatured) return;

        this.onResize();
        this.setResize();
    }

    setResize() {
        if (!this.topEl) return;
        window.addEventListener('resize', this.onResize.bind(this));
    }

    onResize() {
        if (!this.topEl || !this.tagsEl.length) return;
        const { innerWidth } = window;

        const contentStyles = getComputedStyle(this.contentEl);
        const contentPadLeft = parseFloat(
            contentStyles.getPropertyValue('padding-left')
        );
        const contentPadRight = parseFloat(
            contentStyles.getPropertyValue('padding-right')
        );

        const contentDim = this.contentEl.getBoundingClientRect();
        const dateDim = this.dateEl.getBoundingClientRect();

        const contentWidth =
            contentDim.width - contentPadLeft - contentPadRight;
        let dateWidth = dateDim.width;
        let gap = 8;

        if (innerWidth < 992) {
            //dateWidth = 0;
            //gap = 0;
        }

        const tagWrapperMaxWidth = contentWidth - dateWidth - gap; //flex gap subtract
        const tagMaxWidth = tagWrapperMaxWidth / this.tagsEl.length;

        this.tagsEl.forEach(item => {
            item.style.maxWidth = `${tagMaxWidth}px`;
        });
    }
}

export default function initArticleCards() {
    const sections = document.querySelectorAll('.card-article');
    if (sections) sections.forEach(item => new CardArticle(item));
}
