class Marquee {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.speed = 0.2;
        this.cloneCount = 10;
        this.interval = null;

        return true;
    }

    init() {
        this.setMarquee();
    }

    setMarquee() {
        const clone = this.root.innerHTML;
        const firstElement = this.root.children[0];
        let i = 0;

        for (let j = 0; j < this.cloneCount; j++) {
            this.root.insertAdjacentHTML('beforeend', clone);
        }

        /*this.interval = setInterval(
            function () {
                firstElement.style.marginLeft = `-${i}px`;
                if (i > firstElement.clientWidth) {
                    i = 0;
                }
                i = i + this.speed;
            }.bind(this),
            0
        );*/
    }
}

export default function setMarquees() {
    const marquees = document.querySelectorAll('[data-marquee]');
    if (marquees) marquees.forEach(item => new Marquee(item));
}
