class SearchResults {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.tabs = this.root.querySelectorAll('[data-search-results-toggle]');
        this.results = this.root.querySelectorAll(
            '[data-search-results-result]'
        );

        return true;
    }

    init() {
        this.initTabs();
    }

    initTabs() {
        if (!this.tabs) return;

        this.onTab = this.onTabClick.bind(this);
        this.tabs.forEach(item =>
            item.addEventListener('click', event => this.onTab(event))
        );
    }

    onTabClick(event) {
        const { currentTarget } = event;
        const target = currentTarget.getAttribute('data-search-results-toggle');
        const targetEl = this.root.querySelector(
            `[data-search-results-result="${target}"]`
        );

        this.resetTabs();
        this.resetResults();

        if (targetEl) targetEl.classList.add('active');
        currentTarget.classList.add('active');
    }

    resetTabs() {
        if (!this.tabs) return;
        this.tabs.forEach(item => item.classList.remove('active'));
    }

    resetResults() {
        if (!this.results) return;
        this.results.forEach(item => item.classList.remove('active'));
    }
}

const initSearchResults = () => {
    const sections = document.querySelectorAll('[data-search-results]');
    if (sections) sections.forEach(item => new SearchResults(item));
};

export default initSearchResults;
